import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
      className="about"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="About-title">
        <h1>who am i</h1>
        <div className="About-Text">
          <p>
            electrical &amp; software engineer with a focus on high performance
            and data-centric applications. i have hands-on experience building
            linux software, analogue electronics, web development projects, and
            contributing to open-source. outside of tech, i enjoy music
            production, photography, and art.
            <br />
            <br />
            for details on my education and work experience, please refer
            to&nbsp;
            <a
              href="/Ali%20Quttaineh%20-%20CV.pdf"
              target="_blank"
              className="download-cv"
            >
              my CV
            </a>
            .
          </p>
          <div></div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
